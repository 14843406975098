<template>
  <div class="transfer-modal">
    <default-transfer-modal v-on="$listeners" v-bind="$props" :showDeputyClient="true" :showDeliveryType="false"
                            :showPrice="true"/>
  </div>
</template>

<script>
import DefaultTransferModal from "@/components/default_transfer_modal";

export default {
  name: "TransferModal",
  components: {DefaultTransferModal},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'create'
    },
    title: {
      type: String,
      default: ''
    },
    showDeliveryType: {
      type: [Boolean, Function],
      default: true
    },
    showPrice: {
      type: [Boolean, Function],
      default: false
    },
    showDeputyClient: {
      type: [Boolean, Function],
      default: false
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>